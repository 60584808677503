.document-card {
  width: 180px;
  height: 300px;
  border: 1px solid #777777;
  padding: 10px;
  margin: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.document-card:hover {
  cursor: pointer;
  border: 1px solid #919b9a;
  background-color: #e4eff9;
  -webkit-box-shadow: 0px 3px 30px -4px rgba(168, 168, 168, 0.8);
  box-shadow: 0px 3px 30px -4px rgb(168 168 168/ 80%);
}
.document-card-image {
  width: 110px;
  height: 180px;
  display: flex;
  justify-content: center;
  .img {
    height: 100%;
  }
}
.document-card-text {
  font-size: small;
  font-weight: 400;
  text-align: center;
  padding-top: 10px;
  color: #4d4d4d;

  p {
    font-weight: 800;
    // height: 50px;
    // width: 200px;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
  }
}
