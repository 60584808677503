.checkbox {
  display: flex;
  align-items: flex-start;
  margin: 30px 0;

  button {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    outline: none;
    margin-right: 10px;
    position: relative;
    margin-top: 5px;

    div {
      width: 90%;
      height: 90%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  p {
    width: 90%;
    margin-top: 0;
  }
}
