.footer {
  height: 50px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    width: 100%;
    margin: 0 10px;
    background-color: #a00000;
    color: white;
    transition: 0.5s all ease;

    &:hover {
      background-color: #a00000;
      transform: scale(1.05);
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 565px) {
  .footer {
    img {
      width: 100%;
      height: auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 66px;
    }
  }
}
