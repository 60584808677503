.wrapper-block {
  height: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
}
// .content-block{
//     height: 100%;
//     width: 100%;
// }
.footer {
  height: 67px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.upload-file-block {
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
