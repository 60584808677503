/* You can add global styles to this file, and also import other style files */
// .main-block{
//     padding: 0px
// }
// .cards-block {
//   display: flex;
//   flex-wrap: wrap;
// }

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.content-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  width: 60%;
  margin: 0 auto;
}

:root {
  --canvas-gap: 24px;
}

.pdf-canvas-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--canvas-gap);
}

.pdf-canvas {
  width: 100%;
  margin: 0 auto;
  touch-action: none;
}

.pdf-inputs-wrapper {
  position: absolute;
  gap: var(--canvas-gap);
  inset: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  > div {
    flex: 1;
    position: relative;
  }
}

.pdf-region {
  position: absolute;
  z-index: 2;
  border: 2px solid #d4d4d4;
  background: #dbeafe45;
  pointer-events: all;
}

.pdf-region-signature {
  border: 2px solid #fecdd3;
  pointer-events: none;
}

@media only screen and (max-width: 1440px) {
  .content-block {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  .content-block {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .content-block {
    width: 90%;
  }

  .footer {
    margin-top: 10px;
  }
}
