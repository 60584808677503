.main-block {
  padding: 0px;
}

.cards-block {
  display: flex;
  flex-wrap: wrap;
}

.documents-block-wrapper {
  display: flex;
  flex-direction: column;
}
.documents-title-block {
  h2 {
    font-size: 18px;
    padding: 0 25px;
    font-weight: 700;
    // color: red;
  }
}

.content-wrapper {
  // position: relative;
  // height: 84vh;
  // padding-bottom: 100px;
  // overflow: scroll;
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;

  image,
  img,
  video {
    max-width: 35em;
  }
}

@media only screen and (max-width: 580px) {
  .cards-block {
    flex-direction: column;
    align-items: center;
  }
}
