.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-block {
  height: 100%;
  img {
    border: 1px solid grey;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
  }
}
.button-block {
  padding-bottom: 2em;
}
