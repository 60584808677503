.card {
  width: 250px;
  height: 250px;
  border: 2px solid #a00000;
  padding: 10px;
  margin: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card:hover {
  cursor: pointer;
  border: 1px solid #2ec14e;
  background-color: #e4eff9;
  -webkit-box-shadow: 0px 3px 30px -4px rgba(134, 179, 132, 0.8);
  box-shadow: 0px 3px 30px -4px rgb(87 138 91 / 80%);
}
.card-image {
  width: 190px;
  height: 140px;
  display: flex;
  justify-content: center;
  .img {
    height: 100%;
  }
}
.card-text {
  // font-size:medium;
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
  color: #a00000;
}

@media only screen and (max-width: 768px) {
  .card {
  }
  .card-image {
    width: 150px;
    height: 100px;
  }
}
