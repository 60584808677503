.drop-zone:hover {
  background-color: #3f51b557;
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;
}
.drop-zone {
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: '20px';
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  img {
    width: 70px;
  }
}

@media screen and (max-width: 425px) {
  .drop-zone-wrapper {
    height: 280px;
  }
}

@media screen and (max-width: 768px) and (min-width: 445px) {
  .drop-zone-wrapper {
    height: 280px;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .drop-zone-wrapper {
    height: 430px;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1441px) {
  .drop-zone-wrapper {
    height: 600px;
  }
}
