.document-sent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  &__content {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    padding: 30px;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: white;
    border-radius: 4px;

    p {
      font-size: 25px;
      color: #02a10c;
    }

    button {
      background-color: #a00000;
      padding: 5px 10px;
      margin-top: 20px;
      color: white;
      border-radius: 4px;
    }
  }
}


.close-zoom-header{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height: 60px;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  background-color:rgba(0, 0, 0, 0.6);
  padding-left: 20px;
  padding-right: 20px;
  transition: opacity 0.3s ease-in-out;
}
.close-zoom-header-button{
  display: flex;
}